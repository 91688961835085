<!-- 确认弹窗 -->
<template>
  <div class="">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="60%"
      :z-index="12"
      @close="closeModal"
      :close-on-click-modal="false"
      
    >
      <div class="top">
        <div style="font-size: 0.07rem; font-weight: 800; margin-bottom: 1%">
          事件编号：{{ num }}
        </div>
        <div>事件名称：{{ name }}</div>
      </div>
      <div class="center-top">
        <a-descriptions layout="vertical">
          <a-descriptions-item label="登记时间">
            2024年7月10日10:45:41
          </a-descriptions-item>
          <a-descriptions-item label="上报人">张三</a-descriptions-item>
          <a-descriptions-item label="联系方式">
            11111111111</a-descriptions-item
          >
          <a-descriptions-item label="事件类别"> 居民纠纷 </a-descriptions-item>
          <a-descriptions-item label="事件来源">居民上报 </a-descriptions-item>
          <a-descriptions-item label="处理期限">
            <div style="color: #f95a5a" v-if="status === 'returned'">
              已退回
            </div>
            <div style="color: #f95a5a" v-else-if="status === 'aborted'">
              已中止
            </div>
            <div style="color: #f95a5a" v-else-if="status === 'overtime'">
              超时 {{ formatTime(overtime) }}
            </div>
            <div v-else-if="status === 'running' || status === 'delayed'">
              <Countdown
                :start-time="startTime"
                :end-time="endTime"
                @finish="onCountdownEnd"
              />
            </div>
          </a-descriptions-item>
          <!-- <a-descriptions-item label="处理期限">
            <Countdown
              :start-time="startTime"
              :end-time="endTime"
            />
          </a-descriptions-item> -->
          <a-descriptions-item label="事件发生地点"
            >河南省济源市徐舍镇烟山村欢堂39号1号楼2单元603号
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <!-- 以下部分是动态变化的所以应该使用多个页面文件进行渲染 -->
      <div class="center-bottom">
        <!-- 左边竖线样式写法 -->
        <div style="display: flex; align-items: center; margin-bottom: 2%">
          <div style="border-left: 4px solid #556bff">&nbsp;</div>
          <div class="search-title">流程信息</div>
        </div>
        <div class="flow">
          <a-steps
            :current="current"
            type="navigation"
            size="small"
            @change="onChange"
            style="background-color: #f5f7fa; border: 1px solid #e2e8f1"
          >
            <a-step
              title="事件信息"
              :status="status1 >= 0 ? 'finish' : 'wait'"
            />
            <a-step
              title="受理派遣"
              :status="status1 >= 1 ? 'finish' : 'wait'"
              :disabled="status1 < 1 ? true : false"
            />
            <a-step
              title="人员接收"
              :status="status1 >= 2 ? 'finish' : 'wait'"
              :disabled="status1 < 2 ? true : false"
            />
            <a-step
              title="处理反馈"
              :status="status1 >= 3 ? 'finish' : 'wait'"
              :disabled="status1 < 3 ? true : false"
            />
            <a-step
              title="任务核查"
              :status="status1 >= 4 ? 'finish' : 'wait'"
              :disabled="status1 < 4 ? true : false"
            />
            <a-step
              title="办理完结"
              :status="status1 >= 5 ? 'finish' : 'wait'"
              :disabled="status1 < 5 ? true : false"
            />
          </a-steps>
          <div class="flow-info">
            <!-- 事件信息 --><!-- 事件接收 -->
            <event-information
              v-if="current === 0 || current === 2"
              :status1="status1"
              :steps="steps"
            ></event-information>
            <!-- 受理派遣 -->
            <accept-dispatch
              ref="acceptDispatch"
              v-if="current === 1"
              :status1="status1"
              :steps="steps"
            ></accept-dispatch>
            <!-- 处理反馈 -->
            <handling-feedback
              v-if="current === 3"
              :status1="status1"
              :steps="steps"
            ></handling-feedback>
            <!-- 任务核查 -->
            <task-verification
              v-if="current === 4"
              :status1="status1"
              :steps="steps"
            ></task-verification>
            <!-- 处理完结 -->
            <completed-processing
              v-if="current === 5"
              :status1="status1"
              :steps="steps"
            ></completed-processing>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "./api";
import Countdown from "../components/Countdown.vue";
// 事件信息 人员接收
import EventInformation from "./eventInformation/eventInformation.vue";
// 事件派遣
import AcceptDispatch from "./acceptDispatch/acceptDispatch.vue";
// 处理反馈
import handlingFeedback from "./handlingFeedback/handlingFeedback.vue";
// 任务核查
import TaskVerification from "./taskVerification/taskVerification.vue";
// 办理完结
import CompletedProcessing from "./completedProcessing/completedProcessing.vue";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    Countdown,
    EventInformation,
    AcceptDispatch,
    handlingFeedback,
    TaskVerification,
    CompletedProcessing,
  },
  data() {
    //这里存放数据
    return {
      api,
      visible: false,
      num: 2024020216548522,
      title: "",
      name: "事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨阿斯顿法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法事件萨法件萨法",
      startTime: "2024-07-10 11:26:33", // 处理期限
      endTime: "2024-07-17 11:26", // 登记时间
      // startTime: new Date().getTime(), // 当前时间
      // endTime: new Date().getTime() + 10000, // 处理期限（10秒后）
      status: "aborted", // 事件状态：running(进行中), returned(已退回), delayed(延期), aborted(已终止), overtime(已超时)
      overtime: 0, // 超时时间（秒）
      overtimeInterval: null, // 超时计时器
      // time: 6666,
      current: 0,
      status1: 5,
      steps: [
        {
          title: "张三上报了[111]事件",
          time: "2024-01-02 14:35:58",
          color: "blue",
        },
        {
          title: "步骤二",
          time: "2024-01-02 14:38:58",
          color: "green",
        },
        {
          title: "步骤三",
          time: "2024-01-02 14:40:58",
          color: "red",
        },
      ],
      dispatchEvent: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    status(newStatus) {
      if (newStatus === "overtime") {
        this.startOvertimeCounter();
      } else {
        this.clearOvertimeCounter();
      }
    },
  },
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.selectedRowKeys = [];
      this.visible = true;
      this.title = record.title;
      // 假设事件状态是通过某种方式（比如API调用）获取的
      // 这里直接模拟状态变化
      setTimeout(() => this.updateStatus("delayed"), 5000); // 5秒后，模拟延期
      setTimeout(() => this.updateStatus("returned"), 15000); // 15秒后，模拟退回
      setTimeout(() => this.updateStatus("aborted"), 25000); // 25秒后，模拟中止
      setTimeout(() => this.updateStatus("running"), 35000); // 35秒后，模拟恢复
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRow = row[0];
    },
    // 保存
    transmissionId() {
      if (this.selectedRowKeys.length == "0") {
        this.visible = true;
        this.$message.warning("请选择居民");
      } else {
        this.visible = false;
        this.$emit("choose", this.selectedRow);
      }
    },
    formatTime(seconds) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let secs = seconds % 60;
      return `${hours}小时${minutes}分钟${secs}秒`;
    },
    onCountdownEnd() {
      if (this.status === "running" || this.status === "delayed") {
        this.status = "overtime";
      }
    },
    startOvertimeCounter() {
      this.overtimeInterval = setInterval(() => {
        this.overtime++;
      }, 1000);
    },
    clearOvertimeCounter() {
      clearInterval(this.overtimeInterval);
      this.overtime = 0;
    },
    updateStatus(newStatus) {
      this.status = newStatus;
      if (newStatus === "delayed") {
        let delayTime = 20000; // 延期时间（20秒）
        this.endTime += delayTime;
      }
    },
    onChange(current) {
      console.log("onChange:", current);
      this.current = current;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang='less' scoped>
.top {
  background-color: #e8f3ff;
  border-radius: 10px;
  padding: 1%;
}
.center-top {
  margin-top: 1%;
}
.center-bottom {
  margin-top: 1%;
  /deep/.ant-descriptions-item-content {
    width: 100%;
  }
}
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.ant-descriptions-item-label {
  color: #7d8da6;
}
/deep/.ant-descriptions-item-content {
  color: #f95a5a;
}
/deep/.ant-descriptions-item-content:nth-child(1) {
  color: #2d3138;
}
</style>